import { rundomAnim } from "../../@utils/utils-globals"
import { OwnIcon } from "../icons/module-icons"

export const BoxLoader = ({className, ...props}) => {
  if(!props.isShow) return null
  return(
    <div className={`box-loader ${className}`}>
     <div className={`load-bar ${rundomAnim()}`} ></div>
    </div>
  )
}