import React, { Component } from "react";
import Gallery from 'react-photo-gallery';
import './index.scss'
import { GenerateAtribute } from "../../@utils/generate-atribute";

export class CountDownTimer extends Component {
  static defaultProps = {
    dismiss: null,
    show: null
  };
  mainRef = React.createRef().current
  constructor(props) {
    super(props);
  }
  getElementConfig = (props) => {
    const res = GenerateAtribute(props);
    return res
  }

  _timerCount = null
  _elementList = {}
  generateData = (callback) => {
    // console.log(this.mainRef);
    if(this.mainRef){
      const date = new Date(this.props.media["fox-date"]);
      const daysEl = this.mainRef.querySelector("[data-days]") || this.mainRef.querySelector("[fox-data=days]");
      const hoursEl = this.mainRef.querySelector("[data-hours]") || this.mainRef.querySelector("[fox-data=hours]");
      const minutsEl = this.mainRef.querySelector("[data-minutes]") || this.mainRef.querySelector("[fox-data=minutes]");
      const secondsEl = this.mainRef.querySelector("[data-seconds]") || this.mainRef.querySelector("[fox-data=seconds]");
      
      this._elementList = {
        daysEl,
        hoursEl,
        minutsEl,
        secondsEl,
        date
      }
      callback()
    }
  }

  componentDidMount = () => {
    this.generateData(() => {
      this.init()
    })
  }

  counting = () => {
    const {
      daysEl,
      hoursEl,
      minutsEl,
      secondsEl,
      date
    } = this._elementList;
    const dateNow = new Date();
    const diffDays = parseInt((date - dateNow) / (1000 * 60 * 60 * 24), 10);
    const hoursNow = new Date(date)
    hoursNow.setDate(dateNow.getDate())
    hoursNow.setMonth(dateNow.getMonth())
    hoursNow.setFullYear(dateNow.getFullYear());
    if(daysEl){
      daysEl.innerHTML = diffDays > 0 ? diffDays : 0
    }
    if(hoursEl){
      hoursEl.innerHTML = parseInt(date -dateNow ) > 0 ? 24 - dateNow.getHours() : 0
    }
    if(minutsEl){
      minutsEl.innerHTML = parseInt(date - dateNow) > 0 ? 60 - dateNow.getMinutes(): 0
    }
    if(secondsEl){
      secondsEl.innerHTML = parseInt(date - dateNow) > 0 ? 60 - dateNow.getSeconds(): 0
    }
    
  }

  init = () => {
    clearInterval(this._timerCount);
    this._timerCount = setInterval(this.counting,1000)
  }

  componentWillUnmount = () => {
    clearInterval(this._timerCount);
  }

  render() {
    const { props, state } = this;
    
    
    return (
      <section ref={(ref) => this.mainRef =ref} className={`countdown-timer ${props.className}`} {...this.getElementConfig(props)}>
        {props.children}
      </section>
    );
  }
}

