import React, { Component, createElement } from "react";
import './index.scss'
import { GenerateAtribute } from "../../@utils/generate-atribute";

const BLACK_LIST_EL_FOR_CHILD = {
  "iframe": true,
  "video": true,
  "img": true ,
  "br": true,
  "input": true,
  "textarea": true,
  "source": true
}


export class HtmlElement extends Component {
  constructor(props) {
    super(props);
  }
  
  getElementConfig = (props) => {
    const res = GenerateAtribute(props);
    if(res["fox-onclick"]){
      res["onClick"] = () => {
        this.props.onClickTrigger?.(props, this.elRef)
      }
    }
    if(props.newKeyId){
      res["newKeyId"] = props.newKeyId
    }
    return res
  }
  
  componentDidMount = () => {
    if(this.props.className?.match(/triggerdone/)){
      this.props.onTriggerDone?.(this.props)
    }
  }

  getRenderText = (str) => {
    // if(str.match(/<(.*?)>/g)){
    //   return ""
    // }
    return str
  }

  render() {
    const { props, state } = this;
    // console.log(props.style);
    if(BLACK_LIST_EL_FOR_CHILD[props.type]){
      return createElement(
        props.type,
        { 
          ...this.getElementConfig(props),
          ref: ref => this.elRef = ref
        },
        props.children,
      );
    }
    return createElement(
      props.type,
      { 
        ...this.getElementConfig(props),
        ref: ref => this.elRef = ref
      },
      this.getRenderText(props.media.text),
      props.children,
    );
  }
}