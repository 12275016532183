export const BASE_URL = ``;
// export const BASE_URL = `${window.location?.origin}/api`;

const DEFAULT_TIMEOUT = 60000;

const generateCallbackResponseType = ( { data = null, isFound = false, isFetching = false, isError =  false,progress = 0, ...resParams } ) => {
  return {...resParams, data, isFetching, isFound, isError}
}

const createApiRequest = ({ token, headers }) => {
  const temp = {
    // "Accept": "*",
    // "Access-Control-Allow-Headers" : "*",
    // "Access-Control-Allow-Origin": "*",
    // 'Content-Type': 'application/json',
    // "Access-Control-Allow-Methods": "*",
    // "Origin": "https://ulamulemdb.infinityfreeapp.com",
    ...headers,
  }
  if (token){ temp["Authorization"] = `Bearer ${token}`}
  return temp
}

const onProgress = (e, oReq = null,  throwCallback) => {
  const stateProgress = (e.loaded / e.total) * 100
  throwCallback(generateCallbackResponseType({
    isFetching: true,
    progress: stateProgress,
    request: oReq,
    ...e
  }))
}

const onError = (e, oReq = null, throwCallback) => {
  throwCallback(generateCallbackResponseType({
    ...e,
    isError: true,
    progress: 0,
    request: oReq,
  }))
}


const onFinish = (e, oReq = null,  throwCallback) => {
  let data = oReq ? JSON.parse(oReq.responseText) : e;
  if (oReq?.readyState == XMLHttpRequest.DONE || ( data && !data?.errors)) {
    throwCallback(generateCallbackResponseType({
      ...e,
      isFound: true,
      progress: 100,
      request: oReq,
      data,
    }))
  } else {
    throwCallback(generateCallbackResponseType({
      ...e,
      isError: true,
      request: oReq,
      data,
    }))
  }
}

const HeaderDefaultValue = {

}

export const ResponseServerCreator = ( { isFound = false, isFetching = false, isError =  false, ...resParams } ) => {
  return {...resParams, isFetching, isFound, isError}
}


const convertObtToForm = (obj) => {
  const form  =  new FormData();
  for (const key in obj) {
    form.append(key, obj[key])
  }
  return form
}

const createParams = (obj) => {
  const arr = [];
  for (const key in obj) {
    if(obj[key]){
      arr.push(`${key}=${encodeURIComponent(obj[key])}`)
    }
  }
  if(arr.length > 0) return `?${arr.join('&')}`
  return ''
}
export class ServerRequest {
  constructor(baseUrl){
    if(baseUrl){
      this.baseUrl = baseUrl;
    }
  }
  baseUrl = BASE_URL;
  getDataXmlHttp = async ({ url, body, token, params, headers = HeaderDefaultValue  }, method, throwCallback ) => {
    headers = createApiRequest({token, headers});
    const oReq = new XMLHttpRequest();
    throwCallback(generateCallbackResponseType({
      isFetching: true,
      request: oReq,
    }))
  
    try {
      if (oReq.upload) {
        oReq.upload.onprogress = (e) => onProgress(e, oReq, throwCallback);
        oReq.upload.onerror = (e) => onError(e, oReq, throwCallback);
      } else {
        oReq.onprogress = (e) => onProgress(e, oReq, throwCallback);
      }
      oReq.onerror = (e) => onError(e, oReq, throwCallback);
      oReq.onload = (e) => onFinish(e, oReq, throwCallback);
      
      oReq.open(method, `${this.baseUrl}${url}${createParams(params)}`, true)
      for (const key in headers) {
        oReq.setRequestHeader(key, headers[key] || '')
      }
      oReq.send(body)
    } catch ( data ) {
      onError({}, oReq, throwCallback)
    }
  }

  getDataFetch = ({ url, body, token, params, headers = HeaderDefaultValue  }, method, throwCallback ) => {
    headers = createApiRequest({token, headers});
    throwCallback(generateCallbackResponseType({
      isFetching: true,
    }))
    var link = `${this.baseUrl}${url}${createParams(params)}`;

    fetch(link, {
      method: method,
      base: window.location.origin,
      // mode: 'cors', // no-cors, *cors, same-origin
      // // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'omit', // include, *same-origin, omit
      // // redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      headers: headers || {
        
      },
      // referrerPolicy: "unsafe-url" ,
      // // "referrer": "http://localhost:3002/",
      // "referrerPolicy": "strict-origin-when-cross-origin",

      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'strict-origin-when-cross-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body,
    }).then(async(response) => {
      // if (!response.ok) {
      //   onError(response.json(), null, throwCallback)
      //   return
      // }
      const resp = await response.json()
      return {...resp,ok: response.ok}
  }).then((data) => {
    if (data.errors || !data.ok) {
      onError(data, null, throwCallback)
      return
    }
    onFinish(data, null, throwCallback)
  });
    
  }

  get = async (apiBody, callback ) => {
    // apiBody.headers = {
    //   'Content-Type': 'application/json',
    // }
    this.getDataFetch(apiBody, 'get', callback);
  }
  post = async (apiBody, callback ) => {
    if(apiBody.body){
      apiBody.body = convertObtToForm(apiBody.body)
    }
    // apiBody.headers = {
    //   'Content-Type': 'application/json',
    // }
    this.getDataFetch(apiBody, 'post', callback);
  }
  put = async (apiBody, callback ) => {
    if(apiBody.body){
      apiBody.body = JSON.stringify(apiBody.body)
    }
    apiBody.headers = {
      'Content-Type': 'application/json',
    }
    this.getDataFetch(apiBody, 'PATCH', callback);
  }
  delete = async (apiBody, callback ) => {
    this.getDataFetch(apiBody, 'delete', callback);
  }
  file = async (apiBody, callback ) => {
    this.getDataXmlHttp(apiBody, 'post', callback);
  }

}
