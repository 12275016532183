


import React, { Component } from "react";
import { HtmlElement } from "./html-element";
import {  Galery } from "./galery";
import { OddoMetter } from "./oddo-metter";
import { StyleEl } from "./style-element";
import { ParticleJS } from "./particle";
import { Parameters } from "./parameters";
import { CountDownTimer } from "./countdown-timer";
import { Comments } from "./comments";
import { ModalWindows } from "./modal-window";
import { BackgroundSlider } from "./background-slider";
import { GallerySlider } from "./galery-slider";
import { IconEl } from "./icon";
import { Product } from "./product";

const NodeList = {
  htmlElement: HtmlElement,
  galery: Galery,
  oddometter: OddoMetter,
  style: StyleEl,
  particle: ParticleJS,
  parameters: Parameters,
  countdown_timer: CountDownTimer,
  comments: Comments,
  modal_window: ModalWindows,
  galery_background: BackgroundSlider,
  galery_slider: GallerySlider,
  icon: IconEl,
  product: Product
}
// comments, particle, galery_slider, background_slider, parameters
export class Nodes extends Component {
  render() {
    const { props } = this;
    const type = props.type == 'body' ? 'section' : props.type;
    if(type== 'icon'){
      console.log(props);
    }
    const ReturnElement = NodeList[props.type] || HtmlElement
    const className = `${props.className} ${props.media["anim-class-name"] || ""}`;
    return <ReturnElement {...props } type = {type} className = {className}></ReturnElement>
  }
}

