import React, { Component } from "react";
import PhotoAlbum from "react-photo-album";
import './index.scss'
import { GenerateAtribute } from "../../@utils/generate-atribute";

export class Galery extends Component {
  static defaultProps = {
    dismiss: null,
    show: null
  };
  constructor(props) {
    super(props);
    this.state = {
      sizecolumn: 2,
      isLoad: true,
      windowSize: 0
    }
  }
  getElementConfig = (props) => {
    const res = GenerateAtribute(props);
    return res
  }

  componentDidMount = () => {
    this.init()
  }
  
  componentWillUnmount = () => {
    clearInterval(this._timerCount)
  }

  _timerCount = null
  init = () => {
    clearInterval(this._timerCount)
    this._timerCount = setInterval(this.getSize, 1000)
  }

  getSize = () => {
    let sizecolumn = 3;
    if(window.innerWidth <= 768 && window.innerWidth > 520){
      sizecolumn = 3
    }else  if(window.innerWidth <= 520 ){
      sizecolumn = 2
    }else if(window.innerWidth > 768){
      sizecolumn =  5
    }
    if(window.innerWidth != this.state.windowSize){
      this.setState({sizecolumn, isLoad: true, windowSize: window.innerWidth}, () => {
        setTimeout(() => {
          this.setState({isLoad: false})
        }, 500)
      });
    }
  }
  
  getPhoto = (photos) => {
    const res = [];
    for (let i = 0; i < photos.length; i++) {
      const item = photos[i];
      if(!item.isSkip){
        res.push(item)
      }
    }
    return res
  }

  render() {
    const { props, state } = this;
    const photos = props.galery || [];
    return (
      <section {...this.getElementConfig(props)} style={{...this.getElementConfig(props).style, width:'100%', }}  className={`fox-galery ${props.className}`} >
        {/* <Gallery photos={photos} />; */}
        { !state.isLoad &&
          <PhotoAlbum layout="columns" photos={this.getPhoto(photos)} columns={state.sizecolumn} />
        }
      </section>
    );
  }
}
