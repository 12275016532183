import React, { Children, Component, useEffect, useRef } from "react";
import PhotoAlbum from "react-photo-album";
import './index.scss'
import { GenerateAtribute } from "../../@utils/generate-atribute";
import { ServerRequest } from "../../@utils/utils-server-request";
import { BoxLoader } from "../../@component/loader/box-loader";

const RenderListItem = (props) => {
  const ref = useRef(null);

  const rerdenderChildren = () => {
    if(ref.current){
      const srcEl = ref.current.querySelectorAll("[fox-data=src]") || []; 
      const titleEl = ref.current.querySelectorAll("[fox-data=title]")|| []; 
      const linkEl = ref.current.querySelectorAll("[fox-data=link]")|| []; 
      const waUrlEl = ref.current.querySelectorAll("[fox-data=url]")|| []; 
      
      [...srcEl].map((el) => { el.setAttribute("src", props.item.src) });

      [...titleEl].map((el) => { el.innerHTML = props.item.title } );

      [...linkEl].map((el) => { el.setAttribute("href", props.item.link) });

      [...waUrlEl].map((el) => {
        el.setAttribute("fox-message", props.item.link)
        el.setAttribute("fox-newKey", props.id)
      });
      
    }
  }
  useEffect(() => {
    rerdenderChildren()
  }, [])

  return(
    <div className="render-item" ref = {ref} >
      {props.children}
    </div>
  )
}

export class Product extends Component {
  static defaultProps = {
    dismiss: null,
    show: null
  };
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }
  getElementConfig = (props) => {
    const res = GenerateAtribute(props)
    return res
  }

  _timer = null;
  componentDidMount = () => {
    clearTimeout(this._timer)
    this._timer = setTimeout(this.getData)
    this.props.node[0] = {}
  }
  getData = async ( ) => {
    this.setState({isFound: false, isFetching: true, isError: false, data: []}, () => {
      new ServerRequest("https://demo.ulamulem.com").get({url: '/api/data.json'},(e) => {
        if(e.isFound){
          this.setState({
            ...e,
            data: e.data?.data || []
          })
        }else if(e.isError){
          this.setState({
            ...e,
            data: this.state.data
          })
        }
      })
    })
  }

  render() {
    const { props, state } = this;
    
    return (
      <section style={{width:'100%'}} className={`fox-product ${props.className}`} {...this.getElementConfig(props)}>
        <BoxLoader isShow = {state.isFetching} />
        { !state.isFetching &&  state.data.map((item, i) => (
          <RenderListItem item = {item} key = {i} parentId = {props.id} id = {`${props.id}-${i}`}>
            {props.children}
          </RenderListItem>
        ))}
      </section>
    );
  }
}
